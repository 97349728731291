document.addEventListener('DOMContentLoaded', function() {
  removePostsInFromHeader();
  renameSummaryReadMoreToJapanese();
  renameBlogListReadMoreToJapanese();
  renameDate('time.summary-metadata-item--date');
  renameDate('time.Blog-meta-item--date');
  renameFirstNameToJapanese();
  renameLastNameToJapanese();
  renamePaginationLinkLabelToJapanese();
  renameFormErrorMessageToJapanese();
  addShowIntroClass();
});

function removePostsInFromHeader() {
  let blogListFilterClass = document.querySelector('header.BlogList-filter');
  if (!blogListFilterClass) { return }
  let textContent = blogListFilterClass.textContent;
  let removePostsIn = textContent.replace(/Posts in /g, '');
  blogListFilterClass.textContent = removePostsIn;
}

function renameSummaryReadMoreToJapanese() {
  let readMoreClass = document.querySelectorAll('a.summary-read-more-link');
  if (!readMoreClass) { return }
  readMoreClass.forEach(e => {
    e.textContent = '詳細を読む →';
  });
}

function renameBlogListReadMoreToJapanese() {
  let readMoreClass = document.querySelectorAll('a.BlogList-item-readmore');
  if (!readMoreClass) { return }
  readMoreClass.forEach(e => {
    e.innerHTML = '<span>詳細を読む</span>';
  });
}

function renameDate(className) {
  let dateClass = document.querySelectorAll(className);
  if (!dateClass) { return }
  dateClass.forEach(e => {
    let postDate = new Date(e.textContent);
    let year = postDate.getFullYear();
    let month = postDate.getMonth() + 1;
    let day = postDate.getDate();
    e.textContent = `${year}/${month}/${day}`;
  });
}

function renameFirstNameToJapanese() {
  let firstName = document.querySelector('.form-item div.first-name .caption');
  if (!firstName) { return }
  firstName.innerHTML = firstName.innerHTML.replace(/First Name/g, '苗字')
}

function renameLastNameToJapanese() {
  let lastName = document.querySelector('.form-item div.last-name .caption');
  if (!lastName) { return }
  lastName.innerHTML = lastName.innerHTML.replace(/Last Name/g, '名前')
}

function renamePaginationLinkLabelToJapanese() {
  let blogListPaginationLinkLabels = document.querySelectorAll('.BlogList-pagination-link .BlogList-pagination-link-label');
  if (!blogListPaginationLinkLabels) { return }
  blogListPaginationLinkLabels.forEach(e => {
    let text = e.textContent;
    if (text == 'Newer') {
      e.textContent = '前へ'
    }
    else if (text == 'Older') {
      e.textContent = '次へ'
    }
  });
}

function renameFormErrorMessageToJapanese() {
  let formWrapper = document.querySelector('.form-wrapper');
  if (!formWrapper) { return }
  let target = document.querySelector('form');
  let mutationObserver = new MutationObserver(function() {
    let fieldErrors = document.querySelectorAll('form .field-error');
    if (fieldErrors.length) {
      fieldErrors.forEach(e => {
        e.textContent = e.textContent.replace(/Please scroll down to review./g, '下にスクロールして確認して下さい。');
        e.textContent = e.textContent.replace(/Please scroll up to review./g, '上にスクロールして確認して下さい。');
        e.textContent = e.textContent.replace(/Your form has encountered a problem./g, 'フォームで問題が発生しました。');
        e.textContent = e.textContent.replace(/is required./g, 'は必須です。');
        e.textContent = e.textContent.replace(/Unknown error, please try again later./g, 'エラーが発生しています。時間をおいてもう一度お試し下さい。');
      });
    }
  });
  mutationObserver.observe(target, {
      attributes: true,
      classList: true,
      subtree: true
  })
}

function addShowIntroClass() {
  let path = location.pathname;
  if (!path) { return }
  if (path == '/story' || path == '/news') {
    let siteClass = document.querySelector('div.Site');
    if (!siteClass) { return }
    siteClass.classList.add('show-intro');
  }
}

window.addEventListener('load', function() {
  let offSet = 0;
  let lastPosition = 0;
  let shareButtons = document.querySelector('.Site .Main-content .Share-buttons');
  if (!shareButtons) { return }
  window.addEventListener('scroll', function () {
    lastPosition = window.scrollY;
    if (offSet > lastPosition) {
      shareButtons.classList.add('show');
    } else {
      shareButtons.classList.remove('show');
    }
    offSet = lastPosition;
  });
});